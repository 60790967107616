import React from 'react';

function CustomPrimaryButton({ btnTextPro,handleOnClick, classNamePro, customWelcomeClass, icon, btnLoading }) {
    return (
        <div onClick={() => {
            if (handleOnClick) {
                handleOnClick();
            }
        }} className={`btn-primary-custom-box ${customWelcomeClass}`}>
            <button disabled={btnLoading} type='submit' className={`btn btn-primary btn-custom-primary skranji-bold font-18 ${classNamePro}`}>{icon} {btnTextPro}</button>
        </div>
    );
}

// CustomPrimaryButton.defaultProps = {
//     btnTextPro: ""
// };

export default CustomPrimaryButton;