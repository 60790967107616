import React from 'react';

function CustomSecondaryButton({ btnTextPro, classNamePro,handleOnClick }) {
    return (
        <div onClick={()=>handleOnClick()} className={`btn-secondary-custom-box ${classNamePro}`}>
            <button className='btn btn-secondary btn-custom-secondary skranji-bold font-18'>{btnTextPro}</button>
        </div>
    );
}

CustomSecondaryButton.defaultProps = {
    btnTextPro: "",
    classNamePro: ""
};

export default CustomSecondaryButton;