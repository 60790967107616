import React from 'react'
import './CustomCheckBox.css';
import { useFormContext } from 'react-hook-form';

function CustomCheckBox({id, value, name, validation}) {

    const { register, formState } = useFormContext();
    const { errors } = formState;

    return (
        <div className={`custom-check-box`}>
            <div className='custom-check-box-inner'>
            <input type="checkbox" id={id} {...register(name, validation)} value={value} name={name} />
            </div>
            {errors[name] && <span className="error-message">{errors[name].message}</span>}
        </div>
    )
}

export default CustomCheckBox