import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SidebarComponent from "../sidebar/SidebarComponent";
import { IoMdCloseCircle } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { burgerShowState } from "../../store/RecoilStore";
import { useRecoilState } from "recoil";


const Layout = () => {


  const [burgerShowInfo, setBurgerShowInfo] = useRecoilState(burgerShowState);


  return (
    <>
      <div className="main-layout">
        
        <SidebarComponent  />
        <main className="main-content table-responsive w-100">
          <Outlet />
        </main>

        <button onClick={() => setBurgerShowInfo(!burgerShowInfo)} className="custom-burger-menu btn btn-primary">{burgerShowInfo ? <IoMdCloseCircle />:<GiHamburgerMenu />}</button>
      </div>
    </>
  );
};

export default Layout;
