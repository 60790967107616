import React from 'react';

function CustomSocialButton({ btnTextPro, classNamePro, handleOnClick, icon }) {
    return (
        <div onClick={() => handleOnClick()} className={`btn-secondary-custom-box ${classNamePro}`}>
            <button className='btn btn-secondary btn-custom-secondary skranji-regular font-18'>
                <img src={icon} className="icon" />
                {btnTextPro}
            </button>
        </div>

    );
}

CustomSocialButton.defaultProps = {
    btnTextPro: "",
    classNamePro: "",
    icon: ""
};

export default CustomSocialButton;