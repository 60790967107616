import axios from "axios";
import {getTokenLocal} from "../utils/Session"

const API_URL = process.env.REACT_APP_API_URL;

const autAPI = axios.create({
    baseURL: API_URL,
  });


export const registerAPI = async (data)=>{
    const res = await autAPI.post("/register",data);
    return res;
} 


export const loginAPI = async (data)=>{
  const res = await autAPI.post("/login",data);
  return res;
} 

export const sendOTPAPI = async (data)=>{
  const res = await autAPI.post("/send-password-otp",data);
  return res;
}

export const verifyOTPAPI = async (data)=>{
  const res = await autAPI.post("/match-otp",data);
  return res;
}

export const resetPasswordAPI = async (data)=>{
  const res = await autAPI.post("/reset-password-web",data);
  return res;
} 

export const editProfileAPI = async (data, id)=>{
  autAPI.defaults.headers.common['Authorization'] = `Bearer ${getTokenLocal()}`
  const res = await autAPI.post(`/update-profile/${id}`,data,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
});
  return res;
} 

export const getProfileAPI = async (id)=>{
  autAPI.defaults.headers.common['Authorization'] = `Bearer ${getTokenLocal()}`
  const res = await autAPI.get(`/other-user-profile/${id}`);
  return res;
} 