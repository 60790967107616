import React from 'react'
import './BuyCoinComponent.css';
import logoleft from '../../assets/images/logo-right.png'
import { FaPlus } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../store/authAtom';


function BuyCoinComponent({handleOnClick}) {
  const [user, setUser] = useRecoilState(userAtom);

  return (
    <div className='buy-coin' onClick={()=>handleOnClick()}>
        <img src={logoleft}  />
        <h5 className='skranji-regular mb-0'>{user?.total_coins ? user?.total_coins : 0}</h5>
        <button className='btn-buy-coin bg-warning'><FaPlus /></button>
    </div>
  )
}

export default BuyCoinComponent