import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/logo.gif';
import sharkskillz from '../assets/images/sharkskillz.png';
import sharkskillz2 from '../assets/images/sharkskillz2.png';
import CustomSecondaryButton from '../components/globals/CustomSecondaryButton';
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton';
import { useNavigate } from 'react-router-dom';

function Register() {
  const navigate = useNavigate();
  return (
    <Container fluid className='p-0'>
      <Row className='g-0'>
        <Col md={12} lg={7}>
          <div className='join-shark'>
            <div className='inner-logo d-flex align-items-center pt-3'>
              <img src={logo} className='register-logo' />
              <img src={sharkskillz} className='logo-pic' />
            </div>
            <div className='inner-text'>
              <h2 className='text-white font-55'>
                Join SharkSkillz and unlock<br />
                your potential.
              </h2>
              <p className='text-white font-22 skranji-regular mt-4'>
                It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. The point of using Lorem Ipsum is
                that it has a more-or-less normal distribution of letters, as opposed to using
                'Content here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </Col>
        <Col md={12} lg={5} className='register d-flex'>
          <div className='d-block mx-auto text-center align-self-center register-inner'>

            <img src={logo} className='register-pic' />

            <img src={sharkskillz2} className='register-pic mb-5' />

            <CustomSecondaryButton
              btnTextPro="Register"
              classNamePro="mb-4"
              handleOnClick={() => {
                navigate('/register-form');
              }}
            />


            <CustomPrimaryButton btnTextPro="Guest User" handleOnClick={() => {
                navigate('/guest');
              }} />

          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Register