import React, { useState } from 'react';
import './CustomTextField.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useFormContext } from 'react-hook-form';

function CustomPasswordField({ label, placeholder, name, icon, classNamePro, validation }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { register, formState } = useFormContext();
  const { errors } = formState;

  return (
    <div className={`custom-text-field ${classNamePro}`}>
      <label className='skranji-regular text-white mb-1'>{label}</label>
      <div className="custom-text-field-inner">
        <span className='color-999999'>{icon}</span>
        <input
        {...register(name, validation)}
          type={showPassword ? 'text' : 'password'}
          className="form-control skranji-regular color-999999"
          placeholder={placeholder}
          name={name}
        />
        <span className="password-toggle color-999999" onClick={togglePasswordVisibility}>
          {showPassword ? (
            <FaEyeSlash />
          ) : (
            <FaEye />
          )}
        </span>
      </div>
      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </div>
  );
}

// CustomPasswordField.defaultProps = {
//   isRequired: true,
// };

export default CustomPasswordField;
