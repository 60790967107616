import React from 'react'
import './LeaderBoardImage.css'
import profileimage from '../../assets/images/profile-image.png'
import crown from '../../assets/images/crown.png'


function LeaderBoardImage({leaderBoardImg, awardImage, isFirst, profileImage}) {
  return (
    <div className={`${leaderBoardImg}`}>
      {isFirst &&(
        <img src={crown} className={`crown-image`} alt="Profile Image" />
      )}
        <img src={profileImage} className={`profile-image`} alt="Profile Image" />
        <img src={awardImage} className='award-image' />
    </div>

  )
}

LeaderBoardImage.defaultProps = {
  isFirst: false

};

export default LeaderBoardImage