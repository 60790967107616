import React, { useState, useEffect } from 'react'
import profileimage from '../assets/images/profile-image.png'
import CustomTextField from '../components/globals/CustomTextField'
import CustomPasswordField from '../components/globals/CustomPasswordField'
import { FaEnvelope, FaLock, FaUserAlt } from 'react-icons/fa'
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton'
import { useNavigate } from 'react-router-dom'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { FaLocationDot } from 'react-icons/fa6'
import CustomSelectCountryField from '../components/globals/CustomSelectCountryField'
import { useForm, FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { userAtom } from "../store/authAtom"
import { editProfileAPI } from '../services/authAPI'
import { FaPencilAlt } from "react-icons/fa";
import { snakeBarState, isLoaderState } from "../store/RecoilStore"
import { apiPost } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';



function EditProfile() {

    const navigate = useNavigate();

    const [user, setUser] = useRecoilState(userAtom);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


    const [errMsg, setErrMsg] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);

    const methods = useForm();
    const { reset, setValue, watch } = methods;

    let profileImg = watch('image');

    useEffect(() => {
        reset({
            name: user.name,
            email: user.email,
            country: user.country,
        })
    }, [])


    const onSubmit = (data) => {
        setBtnLoading(true)
        setIsLoaderInfo(true)
        apiPost(
            `${ENDPOINTS.UpdateProfile}/${user.id}`,
            data,
            (res) => {
                setUser(res.data)
                setBtnLoading(false)
                setIsLoaderInfo(false)
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-primary",
                    snackMsg: "update successfully",
                });
            },
            (err) => {
                console.log("err", err)
                setErrMsg(err.data)
                setBtnLoading(false)
                setIsLoaderInfo(false)
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: err.data,
                });
            });
    };


    const displayImage = profileImg ? URL.createObjectURL(profileImg) : user?.profile_picture ? `${process.env.REACT_APP_BASE_URL}/${user?.profile_picture.media_path}` : profileimage;


    return (
        <div className='quest'>
            <div className="container-fluid pb-3">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className='row'>
                            <CustomPrimaryButton
                                btnTextPro={<MdOutlineKeyboardArrowLeft size={40} />}
                                classNamePro="custom-edit-profile-btn"
                                customWelcomeClass="custom-edit-profile"
                                handleOnClick={() => {
                                    navigate('/admin/profile');
                                }}
                            />
                            <div className='col-lg-12'>
                                <div className="container custom-space-container text-center">
                                    <div className="profile pt-5 d-inline-block">
                                        <div className="profile-image-container d-inline-block">
                                            <img src={displayImage} className="profile-image" alt="Profile Image" />

                                            <div className='img-upload'>
                                                <label htmlFor="image" className=''>
                                                    <FaPencilAlt />
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file) {
                                                                setValue("image", file)
                                                            }
                                                        }}
                                                        name={"image"}
                                                        id={"image"} className='' />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="text-white">
                                            <h2 className="skranji-regular mt-4">{user.name}</h2>
                                            <p className="mt-2">{user.email}</p>
                                        </div>
                                    </div>



                                    <CustomTextField
                                        icon={<FaUserAlt />}
                                        label="User name"
                                        placeholder="Chris Wayne"
                                        name="name"
                                        type="text"
                                        classNamePro="mb-2"
                                        validation={{
                                            required: "This field is required",
                                        }}
                                    />

                                    <CustomTextField
                                        icon={<FaEnvelope />}
                                        label="Email"
                                        placeholder="chriswayne@gmail.com"
                                        name="email"
                                        type="email"
                                        classNamePro="mb-2"
                                        readOnly={true}
                                        validation={{
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: "Please enter valid email",
                                            },
                                        }}
                                    />

                                    <CustomSelectCountryField
                                        label="Change Nationality"
                                        classNamePro="mb-2"
                                        name="country"
                                        validation={{
                                            required: "This field is required",
                                        }}
                                    />

                                    {/* <CustomPasswordField
                                        label="Password"
                                        placeholder="************"
                                        name="password"
                                        type="password"
                                        icon={<FaLock />}
                                        classNamePro="mb-2"
                                        validation={{
                                            required: "This field is required",
                                        }}
                                    /> */}

                                    {/* <CustomPasswordField
                                        label="Confirm Password"
                                        placeholder="************"
                                        name="password_confirmation"
                                        type="password"
                                        icon={<FaLock />}
                                        classNamePro="mb-2"
                                        validation={{
                                            required: "This field is required",
                                        }}
                                    /> */}

                                    {errMsg && <span className="error-message">{errMsg}</span>}

                                    <div className='mt-4'>
                                        <CustomPrimaryButton
                                            btnTextPro="Update"
                                            btnLoading={btnLoading}
                                            handleOnClick={() => {
                                                // navigate('/admin/profile');
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>

        </div>
    )
}

export default EditProfile