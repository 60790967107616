import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { getTokenLocal, getUserLocal } from '../../utils/Session';

const AuthRoutes = () => {

    const navigate = useNavigate();

    let auth = getTokenLocal();
    let user = getUserLocal();

    if(!user){
        return <Outlet/>                                                                                                
    }
                                            
    if(!auth){
        return <Navigate to="/login"/>
      }

    if(auth){
        if(user?.email_verified_at ==null){
       
            return  <Navigate to="/verify"/>
        }
    }
  if(auth && user){          
    return <Navigate to="/admin/ocean"/>
  }
    // return(                                                                                                              
    //     !auth || user.email_verified_at? <Outlet/> : <Navigate to="/admin/ocean"/>
    // )
}

export default AuthRoutes;