import React from 'react'
import { FaStar } from 'react-icons/fa'

const StarsThree = ({isEven, isStarColor}) => {
  return (
    <div className={  `stars-three ${isEven?'stars-three-left':'stars-three-right'}`}>
    <span className='star-inner inner-star-1'>
      <FaStar size={30} className={`${isStarColor}`} />
    </span>
    <span className='star-inner inner-star-2'>
      <FaStar size={30} className={`${isStarColor}`} />
    </span>
    <span className='star-inner inner-star-3'>
      <FaStar size={30} className={`${isStarColor}`} />
    </span>
  </div>
  )
}

export default StarsThree