import "./CustomSelectCountryField.css";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useFormContext } from "react-hook-form";
import { userAtom } from "../../store/authAtom";
import { useRecoilState } from 'recoil';



const CustomSelectCountryField = ({ label, classNamePro, name, validation }) => {

  const [user, setUser] = useRecoilState(userAtom);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null); // Change to null initially

  const { register, setValue, formState, reset } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        // setSelectedCountry(data.userSelectValue);

        if(user){
          const userCountry = data.countries.find((item)=>{
            return item.value == user.country
          })
          setSelectedCountry(userCountry)
        }

      }); 
  }, []);


  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: 'white',
      backgroundColor: '#1787d5',
      borderBottom: '1px solid #bababa47',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
    })
  };

  return (
    <div className={`custom-text-field ${classNamePro}`}>
      <label className='skranji-regular text-white mb-1 custom-country-label'>{label}</label>
      <Select
        className="custom-country-select"
        options={countries}
        value={selectedCountry}
        styles={customStyles}
        {...register(name, validation)}
        onChange={(selectedOption) => {
          setValue(name, selectedOption.value)
          setSelectedCountry(selectedOption)
        }}
      />
      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </div>
  );
};

export default CustomSelectCountryField;
