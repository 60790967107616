import React, { useState } from 'react'
import './AccuracyItem.css'
import { ProgressBar } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa';

function AccuracyItem({ welcomeicon, text, progressBar, customTime, isClickedDisabled, onClick, isSelected, goalComplete, customText }) {

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        if (isClickedDisabled == false) {
            setIsClicked(!isClicked);
        }
    };

    return (
        <div
            className={`custom-question-item d-flex  ${goalComplete} ${isSelected ? 'bg-red' : 'bg-white'}`}
            onClick={onClick}
        >
            {welcomeicon && (
                <div className='d-flex align-items-center'>
                    <img src={welcomeicon} className="welcomeicon me-2" alt="icon" />
                    <p className='text-center mb-0 font-18'>{customText}</p>
                </div>
            )}
            <p className='text-end mb-0 font-18 data-accuracy'>{text}</p>
            {progressBar && (
                <ProgressBar now={progressBar.now} className="custom-progress-bar" variant="primary" label={`${progressBar.now}%`} />
            )}
        </div>
    );
}

export default AccuracyItem