import React, { useState } from 'react';
import lockimage from '../assets/images/lockimage.png';
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton';
import { useNavigate } from 'react-router-dom';
import CustomTextField from '../components/globals/CustomTextField';
import { FaEnvelope, FaLock, FaUserAlt } from 'react-icons/fa';
import CustomPasswordField from '../components/globals/CustomPasswordField';
import { useForm, FormProvider } from 'react-hook-form';
import { sendOTPAPI, verifyOTPAPI } from "../services/authAPI"
import PinInput from 'react-pin-input';
import { useRecoilState } from 'recoil';
import { userAtom } from "../store/authAtom"
import { apiPostWithoutAuth } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';
import { snakeBarState, isLoaderState } from "../store/RecoilStore"
import { getUserLocal, setUserLocal } from '../utils/Session';




function VerifyOTP() {


    const navigate = useNavigate();

    const [user, setUser] = useRecoilState(userAtom);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


    const [errMsg, setErrMsg] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);

    const methods = useForm();
    const { setValue, watch } = methods;

    const otpData = watch("otp")


    const onSubmit = (data) => {

        setErrMsg("")
        setIsLoaderInfo(true)
        const payload = {
            email: user?.email,
            otp: data.otp
        }
        apiPostWithoutAuth(
            ENDPOINTS.veriFyOTP,
            payload,
            (res) => {

                let user = getUserLocal();
                user.email_verified_at = '2024-08-30';
                setUser(user)
                setUserLocal(user)
                setIsLoaderInfo(false)
                if (user.is_topic) {
                    navigate("/admin/ocean");
                } else {
                    navigate('/welcome');
                }
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-primary",
                    snackMsg: "OTP sent successfully",
                });
            },
            (err) => {
                setIsLoaderInfo(false)
                console.log("err", err)
                setErrMsg(err.data)
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: err.data,
                });
            }
        );

    };


    const handleResendOTP = () => {
        setIsLoaderInfo(true)
        sendOTPAPI({ email: user?.email, type: "email_otp" }).then((res) => {
            setIsLoaderInfo(false)

        })
        .catch((err) => {
                console.log("err", err)
                setIsLoaderInfo(false)

            })
    }



    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div>
                    <div className='quest d-flex justify-content-center align-items-center'>
                        <div className='container text-white d-flex justify-content-center align-items-center'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h1>Enter OTP code</h1>
                                    <img src={lockimage} className='lockimage mt-5 mb-5' alt="Lock" />
                                    <p className='text-warning text-center mt-2'>Please enter the 4-digit code sent to you</p>
                                    <div className='pincode-field mt-2'>
                                        <PinInput
                                            length={4}
                                            initialValue=""
                                            secret
                                            secretDelay={100}
                                            onChange={(value, index) => { setValue('otp', value, { shouldDirty: true }); }}
                                            type="numeric"
                                            inputMode="number"
                                            style={{ padding: '10px' }}
                                            // inputStyle={{ borderColor: 'red' }}
                                            // inputFocusStyle={{ borderColor: 'blue' }}
                                            onComplete={(value, index) => { setValue('otp', value, { shouldDirty: true }) }}
                                            autoSelect={true}
                                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                        />
                                    </div>
                                    {errMsg && <p className="error-message text-center">{errMsg}</p>}
                                    <p className='text-center mt-2'>Didn’t receive OTP? <span onClick={handleResendOTP} className='text-warning'>Send again!</span></p>
                                    <CustomPrimaryButton btnTextPro="Verify" btnLoading={otpData == undefined || otpData.length < 4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

export default VerifyOTP;
