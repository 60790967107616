import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './CustomModal.css'
import { ProgressBar } from 'react-bootstrap';
import { IoCloseSharp } from 'react-icons/io5';

const CustomModal = ({ title, children, showModal, hideModal, size, isSpecial, progressBar }) => {
  return (

    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`custommodal ${isSpecial ? 'modal-color-gradient' : ''}`}
      show={showModal}
    >
      <Modal.Header className="header-without-border-bottom">
        <Modal.Title id="contained-modal-title-vcenter">
          <IoCloseSharp size={40} color='white' onClick={() => hideModal()}/>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        {children}
        {progressBar && (
          <ProgressBar now={progressBar.now} className="custom-progress-bar-profile mb-5" variant="primary" label={`${progressBar.now}%`} />
        )}
      </div>
    </Modal>

  );
};

CustomModal.defaultProps = {
  size: "lg",
};

export default CustomModal;
