import React, { useState } from 'react'
import CustomQuestBox from '../components/globals/CustomQuestBox'
import CustomBusinessMoneyButton from '../components/globals/CustomBusinessMoneyButton'
import LeaderBoardImage from '../components/globals/LeaderBoardImage'
import one from '../assets/images/1.png'
import two from '../assets/images/2.png'
import three from '../assets/images/3.png'
import { FaClock } from 'react-icons/fa'
import TableComponent from '../components/globals/TableComponent'
import BuyCoinComponent from '../components/globals/BuyCoinComponent'
import QuestionItem from '../components/globals/QuestionItem';
import points from '../assets/images/points.png'
import coin from '../assets/images/coin.png'
import ai from '../assets/images/ai.png'
import analytics from '../assets/images/analytics.png'
import CustomModal from '../components/globals/CustomModal';
import { useNavigate } from 'react-router-dom'
import profile1 from '../assets/images/profile-1.jpeg'
import profile2 from '../assets/images/profile-2.jpeg'
import profile3 from '../assets/images/profile-3.jpeg'
import flag1 from '../assets/images/flag-1.png'
import flag2 from '../assets/images/flag-2.png'
import flag3 from '../assets/images/flag-3.png'

function LeaderBoard() {


  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const data = [
    { id: 4, name: 'Chris Wyne', age: 30, country:<img src={flag1} />, email: '910 XP' },
    { id: 5, name: 'Mark', age: 25, country:<img src={flag2} />, email: '890 XP' },
    { id: 6, name: 'Yulia Clark', age: 40, country:<img src={flag3} />, email: '850 XP' },
    { id: 7, name: 'Jacob', age: 35, country:<img src={flag2} />, email: '800 XP' },
    { id: 8, name: 'John', age: 35, country:<img src={flag1} />, email: '780 XP' },
  ];

  const renderRows = () => {
    return data.map(row => (
      <tr>
        <td>{row.age}</td>
        <td>{row.country}</td>
        <td>{row.name}</td>
        <td>{row.email}</td>
      </tr>
    ));
  };


  return (
    <div className='quest'>
      <div className='container-fluid p-4'>
        <div className='d-flex justify-content-between align-items-center pb-5 custom-leaderboard-mb-class'>
          <CustomBusinessMoneyButton
            btnTextPro="Business and Money Skillz"
            classNamePro="btn-custom-leaderboard"
            customWelcomeClass="custom-leaderboard-class"
            handleOnClick={handleButtonClick}
          />
          <BuyCoinComponent handleOnClick={() => navigate('/admin/coin-purchase')} />
        </div>
        <div className='row d-flex align-items-center'>
          
          <div className='col-lg-6'>
            <CustomQuestBox isLocked={false} isLeaderBoard={true} classNameQuest="custom-challenge-box" />
          </div>

          <div className='col-lg-6'>
            <div class="grid-container pt-5">
              <div class="grid-item">
                <LeaderBoardImage leaderBoardImg="leader-board-image" awardImage={two} profileImage={profile2} />
              </div>
              <div class="grid-item">
                <LeaderBoardImage isFirst={false} leaderBoardImg="leader-board-image first-position" awardImage={one} profileImage={profile1} />
              </div>
              <div class="grid-item">
                <LeaderBoardImage leaderBoardImg="leader-board-image" awardImage={three} profileImage={profile3} />
              </div>
            </div>
            <div className='mt-5 pb-3'>
              {/* <h2 className='text-white skranji-bold text-center'>Top 20 will advance to the next stage</h2>
              <div className='custom-time-leaderboard text-white'>
                <span className='me-2'><FaClock size={25} /></span>
                <span className='skranji-regular'>7 days left</span>
              </div> */}

              <div className='leaderboard-table mt-3'>
                {/* <TableComponent
              bgColor="bg-secondary"
              reloadData
              title=""
            /> */}
                <div className='table-responsive'>
                  <table className='custom-table skranji-regular'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Country</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderRows()}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <CustomModal
        size={'md'}
        hideModal={()=>setShowModal(false)}
        showModal={showModal} 
        title="Select Ocean" onClose={handleCloseModal}>
          <div className='ocean-modal text-center'>
            <h2 className='text-white'>Select Ocean</h2>
            <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 100 }} customTime="4 Hours" />
            <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 30 }} customTime="4 Hours" />
            <QuestionItem welcomeicon={analytics} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
            <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
            <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
          </div>
        </CustomModal>
    </div>
  )
}

export default LeaderBoard