import React, { useState } from 'react'
import './QuestionItem.css'
import { ProgressBar } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa';

function QuestionItem({ welcomeicon, text, progressBar, customTime, isClickedDisabled, onClick, isSelected }) {

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        if (isClickedDisabled == false) {
            setIsClicked(!isClicked);
        }
    };

    return (
        <div className={`custom-question-item ${isSelected ? 'bg-red' : 'bg-white'}`}
            // onClick={handleClick}
            onClick={onClick}
            >
            <div className='d-flex'>
                {welcomeicon &&
                    <img src={welcomeicon} className="welcomeicon me-2" />
                }
                <div>
                    <p className='text-center mb-0 font-18'>{text}</p>
                    {progressBar && (
                        <ProgressBar now={progressBar.now} className="custom-progress-bar   " variant="primary" label={`${progressBar.now}%`} />
                    )}

                </div>
            </div>
            {customTime && (
                <div className='custom-time'>
                    <span ><FaClock /></span>
                    <span>{customTime}</span>
                </div>
            )}
        </div>
    );
}

export default QuestionItem