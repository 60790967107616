import './App.css';
import './custom.scss';
import Layout from './components/layout/Layout';
import ErrorPage from './pages/ErrorPage';
import { Route, BrowserRouter as Router, Routes, Navigate, redirect } from "react-router-dom";
import Ocean from './pages/Ocean';
import Loader from './components/globals/Loader';
import { useRecoilState } from 'recoil';
import { isLoaderState, snakeBarState } from './store/RecoilStore';
import Login from './pages/Login';
import Register from './pages/Register';
import LeaderBoard from './pages/LeaderBoard';
import CoinPurchase from './pages/CoinPurchase';
import Quest from './pages/Quest';
import Wallet from './pages/Wallet';
import Profile from './pages/Profile';
import RegisterForm from './pages/RegisterForm';
import Welcome from './pages/Welcome';
import EditProfile from './pages/EditProfile';
import Stages from './pages/Stages';
import ForgotPassword from './pages/ForgotPassword';
import VerifyOTP from './pages/VerifyOTP';
import Guest from './pages/Guest';
import PrivateRoutes from './components/auth/PrivateRoutes';
import AuthRoutes from './components/auth/AuthRoutes';
import SnackBar from './components/globals/SnackBar';



function App() {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  return (

    <>
      {isLoaderInfo && <Loader />}


      <div className="App">
        <Router>
          <Routes>

            <Route element={<AuthRoutes />}>
              <Route exact index path={'/'} element={<Register />} />
              <Route exact path={'/login'} element={<Login />} />
              <Route exact path={'/register'} element={<Register />} />
              <Route exact  path={'/forget-password'} element={<ForgotPassword />} />
              <Route exact path={'/register-form'} element={<RegisterForm />} />
            </Route>

            {/* Public Routes*/}
            <Route exact path={'/welcome'} element={<Welcome />} />
            <Route  exact path={'/guest'} element={<Guest />} />
            <Route exact path='/stages/:stageId' element={<Stages />} />
            <Route exact path={'/verify'} element={<VerifyOTP />} />

            {/* Private Routes */}
            <Route element={<PrivateRoutes />}>
              <Route path={'/admin'} element={<Layout />}>
                <Route exact path='ocean' element={<Ocean />} />
                <Route exact path='challenge' element={<LeaderBoard />} />
                <Route exact path='quest' element={<Quest />} />
                <Route exact path='wallet' element={<Wallet />} />
                <Route exact path='coin-purchase' element={<CoinPurchase />} />
                <Route exact path='profile' element={<Profile />} />
                <Route exact path='edit-profile' element={<EditProfile />} />
              </Route>
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>

      <SnackBar
              closeSnackPro={() => setSnackBarInfo({ snackStatus: false, snackColor: '', snackMsg: '' })}
              snackInfoPro={snackBarInfo}
          />

    </>
  );
}

export default App;
