export const getTokenLocal = () => {
    return localStorage.getItem("accessToken");
};

export const getUserLocal = () => {
    const result = localStorage.getItem("user");
    if (result) {
        return JSON.parse(result);
    }
    return null;
};

export const setTokenLocal = (data) => {
    localStorage.setItem("accessToken", data);
};

export const setUserLocal = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
};
