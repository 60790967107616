import React from 'react'
import coinImage from '../assets/images/coin-image.png'
import CoinPurchaseComp from '../components/globals/CoinPurchaseComp'

function CoinPurchase() {
  return (
    <div className='coin-purchase'>
      <div className='container'>
        <h1 className='pt-4 text-white'>Coin Purchase:</h1>
        <div className='row mt-5'>
          <div className='col-lg-4'>
            <CoinPurchaseComp
              wallettext="50 Coins"
              walletamount="$0.99"
              walletimage={coinImage}
            />
          </div>
          <div className='col-lg-4'>
            <CoinPurchaseComp
              wallettext="50 Coins"
              walletamount="$2.99"
              walletimage={coinImage}
            />
          </div>
          <div className='col-lg-4'>
            <CoinPurchaseComp
              wallettext="50 Coins"
              walletamount="$3.99"
              walletimage={coinImage}
            />
          </div>
        </div>

        <div className='row mt-5'>
          <div className='col-lg-4'>
            <CoinPurchaseComp
              wallettext="50 Coins"
              walletamount="$5.99"
              walletimage={coinImage}
            />
          </div>
          <div className='col-lg-4'>
            <CoinPurchaseComp
              wallettext="50 Coins"
              walletamount="$7.99"
              walletimage={coinImage}
            />
          </div>
          <div className='col-lg-4'>
            <CoinPurchaseComp
              wallettext="50 Coins"
              walletamount="$9.99"
              walletimage={coinImage}
            />
          </div>
        </div>

      </div>
    </div>

  )
}

export default CoinPurchase