import React from 'react';
import './CustomBusinessMoneyButton.css'
import { IoIosArrowDown } from 'react-icons/io';

function CustomBusinessMoneyButton({ btnTextPro,handleOnClick, classNamePro, customWelcomeClass, icon }) {
    return (
        <div onClick={() => handleOnClick()} className={`btn-business-custom-box overflow-hidden ${customWelcomeClass}`}>
        <button className={`btn btn-warning btn-custom-business font-18 skranji-bold overflow-hidden ${classNamePro}`}>
          {icon}
          {btnTextPro} <IoIosArrowDown />
        </button>
      </div>
    );
}

CustomBusinessMoneyButton.defaultProps = {
    btnTextPro: ""
};

export default CustomBusinessMoneyButton;