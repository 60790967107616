import React, { useEffect, useState } from 'react';
import {
    FaUser,
} from 'react-icons/fa';
import { GiArrowScope, GiWaveSurfer } from "react-icons/gi";
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import './Sidebar.css';
import { BiSolidNotepad } from 'react-icons/bi';
import { IoLogOut, IoWallet } from 'react-icons/io5';
import { TbCoinFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.gif';
import { Button, Modal } from 'react-bootstrap';
import { CgDanger } from 'react-icons/cg';
import { burgerShowState } from '../../store/RecoilStore';
import { useRecoilState } from 'recoil';



const SidebarComponent = ({  }) => {

    const [burgerShow, setBurgerShow] = useState(false);
    const [urlParam, setUrlParam] = useState(window.location.pathname.split('/').pop(),);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();

    // recoil
    const [burgerShowInfo, setBurgerShowInfo] = useRecoilState(burgerShowState);


    const logOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth);
      return () => {
        window.removeEventListener('resize', updateWindowWidth);
      };
    }, []); 
    

    return (
        <>
            {burgerShowInfo && (
                <Sidebar
                    className="sidebar-dashboard"
                    backgroundColor="#00204E"
                    collapsed={burgerShow}
                >
                    <div className="sidebar-dashboard-logo d-flex align-items-center justify-content-center">
                        <div className='d-flex align-items-center'>
                            <img src={logo} className='logo' alt="Logo" />
                            <h4 className='text-warning mb-0 skranji-bold'>Shark Skillz </h4>
                        </div>
                    </div>

                    <Menu iconShape="square">

                        <hr className='custom-hr' />

                        <MenuItem
                            active={urlParam === 'ocean' ? true : false}
                            onClick={() => {
                                setUrlParam('ocean');
                                navigate(`/admin/ocean`);
                                if(windowWidth <= 992){
                                    setBurgerShowInfo(!burgerShowInfo)
                                }
                            }}
                            icon={<GiWaveSurfer />}
                            className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                        >
                            Ocean
                        </MenuItem>

                        <MenuItem
                            active={urlParam === 'challenge' ? true : false}
                            onClick={() => {
                                setUrlParam('challenge');
                                navigate(`/admin/challenge`);
                                if(windowWidth <= 992){
                                    setBurgerShowInfo(!burgerShowInfo)
                                }
                            }}
                            icon={<GiArrowScope />}
                            className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                        >
                            Challenge
                        </MenuItem>

                        <MenuItem
                            active={urlParam === 'quest' ? true : false}
                            onClick={() => {
                                setUrlParam('quest');
                                navigate(`/admin/quest`);
                                if(windowWidth <= 992){
                                    setBurgerShowInfo(!burgerShowInfo)
                                }
                            }}
                            icon={<BiSolidNotepad />}
                            className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                        >
                            Quest
                        </MenuItem>

                        <MenuItem
                            active={urlParam === 'wallet' ? true : false}
                            onClick={() => {
                                setUrlParam('wallet');
                                navigate(`/admin/wallet`);
                                if(windowWidth <= 992){
                                    setBurgerShowInfo(!burgerShowInfo)
                                }
                                
                            }}
                            icon={<IoWallet />}
                            className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                        >
                            Wallet
                        </MenuItem>

                        <MenuItem
                            active={urlParam === 'coin-purchase' ? true : false}
                            onClick={() => {
                                setUrlParam('coin-purchase');
                                navigate(`/admin/coin-purchase`);
                                if(windowWidth <= 992){
                                    setBurgerShowInfo(!burgerShowInfo)
                                }
                            }}
                            icon={<TbCoinFilled />}
                            className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                        >
                            Coin purchase
                        </MenuItem>


                        <div>
                            <p className='custom-side-space text-white'>ACCOUNT PAGES</p>
                            <MenuItem
                                active={urlParam === 'profile' ? true : false}
                                onClick={() => {
                                    setUrlParam('profile');
                                    navigate(`/admin/profile`);
                                    if(windowWidth <= 992){
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<FaUser />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Profile
                            </MenuItem>
                        </div>

                        <hr className='custom-hr' />

                        <MenuItem
                            onClick={() => setModalShow(true)}
                            icon={<IoLogOut />}
                            className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                        >
                            Logout
                        </MenuItem>

                    </Menu>

                </Sidebar>
            )}



            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div className="d-flex gap-2"><h4 className="mb-0 text-danger"><CgDanger /> </h4> <h6 className="my-auto">
                    Are you sure you want to logout ?
                </h6></div> </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={() => logOut()}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal >

        </>
    );
};

export default SidebarComponent;