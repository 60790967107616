import React from 'react'
import CoinWalletCard from '../components/globals/CoinWalletCard'
import walimage1 from '../assets/images/wallet-1.png'
import walimage2 from '../assets/images/wallet-2.png'
import walimage3 from '../assets/images/wallet-3.png'
import TableComponent from '../components/globals/TableComponent'

function Wallet() {

  const data = [
    { id: 4, date: '2022-01-02', time: '10:00 AM', coin_type: 'Shark', coin_type: 'Debit', amount: '12', transaction_type:'23423' },
    { id: 5, date: '2022-01-02', time: '10:00 AM', coin_type: 'Shark',coin_type: 'Debit', amount: '12' , transaction_type:'23423'},
    { id: 6, date: '2022-01-02', time: '10:00 AM', coin_type: 'Shark', coin_type: 'Debit', amount: '12', transaction_type:'23423' },
    { id: 7, date: '2022-01-02', time: '10:00 AM', coin_type: 'Shark', coin_type: 'Debit', amount: '12' , transaction_type:'23423'},
    { id: 8, date: '2022-01-02', time: '10:00 AM', coin_type: 'Shark' ,coin_type: 'Debit', amount: '12', transaction_type:'23423'},
  ];

  const renderRows = () => {
    return data.map(row => (
      <tr>
        <td>{row.date}</td>
        <td>{row.time}</td>
        <td>{row.coin_type}</td>
        <td>{row.transaction_type}</td>
        <td>{row.amount}</td>
        </tr>
    ));
  };


  return (
    <div className='coin-wallet'>
      <div className='container'>
        <h1 className='pt-4 text-white'>Coin Wallet:</h1>
        <div className='row mt-5 justify-content-center'>
          <div className='col-lg-6 col-sm-12 col-xl-4 mb-2'>
            <CoinWalletCard
              wallettext="Coins Purchased"
              walletamount="6k coins"
              walletimage={walimage1}
            />
          </div>
          <div className='col-lg-6 col-sm-12 col-xl-4 mb-2'>
            <CoinWalletCard
              wallettext="Coins Purchased"
              walletamount="6k coins"
              walletimage={walimage2}
            />
          </div>
          <div className='col-lg-6  col-sm-12 col-xl-4 mb-2'>
            <CoinWalletCard
              wallettext="Coins Purchased"
              walletamount="6k coins"
              walletimage={walimage3}
            />
          </div>
        </div>

        <h1 className='pt-4 text-white'>Coin Wallet:</h1>
        
        <div className='row'>
          <div className='col-lg-12'>
            {/* <TableComponent 
            bgColor="bg-secondary"
            title=""
            /> */}
            <div className='table-responsive'>
            <table className='custom-wallet-table skranji-regular'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Coin Type</th>
                  <th>Transaction Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {renderRows()}
              </tbody>
            </table>
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Wallet