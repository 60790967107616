import React from 'react'
import './CustomQuestBox.css'
import BackRacImage from '../../assets/images/rectangle.png'
import CustomPrimaryButton from './CustomPrimaryButton'
import { BiNotepad } from 'react-icons/bi'
import { MdStars } from 'react-icons/md'
import { IoIosLock } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { BsStopwatchFill } from 'react-icons/bs'

function CustomQuestBox({ isLocked, isLeaderBoard , classNameQuest}) {

  const navigate = useNavigate();

  return (
    <div className={`custom-quest-box-parent container-fluid ${classNameQuest}`}>

      <div className='custom-quest-box'>
        <div className='quest-business color-014598'>
          <span><BiNotepad /></span>
          <h6 className='text-white'>Business & money skillz</h6>
          <img src={BackRacImage} />
        </div>
        <div className='quest-business color-014598 rounded'>
          <h6 className='text-white'>Easy</h6>
        </div>
        <div className={`quest-business rounded ${isLocked ? 'color-4D4D4D' : 'color-014598'}`}>
          <h6 className='text-white'>{isLocked ? 'Locked' : 'In progress'}</h6>
        </div>
      </div>

      <div className='row g-5'>
        <div className='col-md-8 col-sm-12 mb-2'>
          <h6 className='text-white custom-border color-014598 h-100 custom-quest-heading rounded'>Answer 8 questions to reach next level.</h6>
        </div>
        <div className='col-md-4 co-sm-12 mb-2'>
          <div className='color-014598 custom-border custom-border h-100 rounded text-center pt-2'>
            <h6 className='text-warning'>Reward</h6>
            <p className='text-warning d-flex align-items-center justify-content-center'><MdStars /> 20</p>
          </div>
        </div>
      </div>

      <div className='row g-5'>
        <div className={`${isLeaderBoard ? 'col-md-8' : 'col-md-12'} col-sm-12 mb-2`}>
          <h6 className='text-warning custom-border color-014598 h-100 custom-quest-heading rounded text-center'>0/10</h6>
        </div>
        {isLeaderBoard && (
          <div className='col-md-4 co-sm-12 mb-2'>
            <div className='color-014598 custom-border h-100 d-flex justify-content-center align-items-center rounded text-center'>
              <p className='text-warning mt-1 me-2'><BsStopwatchFill /></p>
              <h6 className='text-warning'>2:00</h6>
            </div>
          </div>
        )}

      </div>

      <div className='row g-5'>
        <div className='col-md-12 col-sm-12 mb-2'>
          <CustomPrimaryButton
            icon={isLocked ? <IoIosLock size={25} /> : ''}
            btnTextPro={isLocked ? "Locked" : "Start"}
            classNamePro={isLocked ? "btn-custom-quest locked" : "btn-custom-quest"}
            customWelcomeClass={isLocked ? "custom-quest-class locked" : "custom-quest-class"}
            handleOnClick={() => navigate('/stages')}
          />
        </div>
      </div>


    </div>



  )
}

export default CustomQuestBox