import React from 'react'
import './CustomTextField.css'
import { MdGpsFixed } from 'react-icons/md';
import { useFormContext } from 'react-hook-form';

function CustomTextField({label,placeholder,name,type,icon, classNamePro, showGps, validation, readOnly}) {

  const { register, formState } = useFormContext();
  const { errors } = formState;

  
  return (
    <div className={`custom-text-field ${classNamePro}`}>
      <label className='skranji-regular text-white mb-1'>{label}</label>
      <div className="custom-text-field-inner">
        <span className='color-999999'>{icon}</span>
        <input {...register(name, validation)} type={type} className="form-control skranji-regular color-999999" placeholder={placeholder}  name={name} readOnly={readOnly}/>
        <span className="password-toggle color-999999">
          {showGps && (
            <MdGpsFixed />
          )}
        </span>
      </div>
      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </div>
  )
}

// CustomTextField.defaultProps = {
//   isRequired:true,
// };

export default CustomTextField