import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import QuestionItem from '../components/globals/QuestionItem'
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import CustomStageBox from '../components/globals/CustomStageBox'
import StartLesson from '../components/globals/StartLesson'
import { FaVolumeHigh } from 'react-icons/fa6'
import { FaBookReader, FaBusinessTime, FaClock } from 'react-icons/fa'
import jumpingshark from '../assets/images/jumping-shark.gif'
import { GiSkills } from 'react-icons/gi'
import logo from '../assets/images/logo.gif';



function Guest() {

    const [stepForm, setStepForm] = useState(0);
    const totalSteps = 2;

    const handlePrevious = () => {
        if (stepForm > 0) {
            setStepForm(stepForm - 1);
        }
    };

    const navigate = useNavigate();

    if (stepForm === 3) {
        navigate("/admin/ocean");
    }

    return (
        <>
            <Container fluid className='p-0 custom-welcome d-flex flex-column align-items-center justify-content-center'>
                {stepForm == 0 && (
                    <div className='mb-4 stage-btn-first'>
                        <CustomPrimaryButton
                            btnTextPro={<MdOutlineKeyboardArrowLeft size={40} />}
                            classNamePro="custom-progress-btn"
                            handleOnClick={() => {
                                navigate('/register');
                            }}
                        />
                    </div>

                )}
                <Row className='g-0 justify-content-center'>
                    <Col md={12} lg={12} className='align-items-center'>
                        {stepForm == 0 && (
                            <div className='text-center'>
                                <img src={logo} className='coin-stage' />
                                <h1 className='text-white'>Register to get 200 coins</h1>
                                <div className='mt-5'>
                                <CustomPrimaryButton btnTextPro="Register" handleOnClick={() => {
                                    navigate('/register-form');
                                }} />
                                </div>
                            </div>
                        )}

                    </Col>
                </Row>

            </Container>

            <div className='welcome-bottom-box container-fluid'>
                {stepForm !== 1 && (
                    <CustomPrimaryButton
                        btnTextPro="Continue"
                        handleOnClick={() => navigate("/welcome")}
                        classNamePro="btn-custom-welcome"
                        customWelcomeClass="custom-welcome-class"
                    />
                )}
            </div>

        </>
    )
}

export default Guest