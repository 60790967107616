import React from 'react'
import './CoinPurchaseComp.css'
import CustomPrimaryButton from './CustomPrimaryButton'
import { useNavigate } from 'react-router-dom'

function CoinPurchaseComp({ walletimage, wallettext, walletamount }) {

    const navigate = useNavigate();

    return (
        <div>
            <div className='custom-purchase'>
                <div className='custom-purchase-inner '>
                    <img src={walletimage} className='walletimage' />
                    <div>
                        <h3 className='skranji-regular'>{wallettext}</h3>
                        <p className='mb-0 skranji-bold'>{walletamount}</p>
                    </div>
                </div>

                <div className='mt-3'>
                    <CustomPrimaryButton
                        btnTextPro="Buy"
                        handleOnClick={() => navigate('/admin/ocean')}
                    />
                </div>
            </div>


        </div>
    )
}

export default CoinPurchaseComp