
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {

    //User
    UserRegister: `${BASE_URL}register`,
    UserLogin: `${BASE_URL}login`,
    SendOtp: `${BASE_URL}send-password-otp`,
    veriFyOTP: `${BASE_URL}match-otp`,
    ChangePassword: `${BASE_URL}reset-password-web`,
    UpdateProfile: `${BASE_URL}update-profile`,
    GetProfile: `${BASE_URL}other-user-profile`,

    // Topics
    GetTopic: `${BASE_URL}topics`,
    AddTopic: `${BASE_URL}add-topic`,

    // Oceans
    GetOceans: `${BASE_URL}oceans`,
    UserSelectedOption: `${BASE_URL}user-selected-option`,

    // Stages
    GetStages: `${BASE_URL}stages`,

    // Lessons
    GetLessons: `${BASE_URL}lessons`,
    GetCoins: `${BASE_URL}coins`,
    CompleteLesson: `${BASE_URL}is-complete-lesson`,
    CompleteQuiz: `${BASE_URL}is-complete-quiz`,
    StartQuizDeductCoins: `${BASE_URL}start-quiz-deduct-coins`,


    
    AddQuestionAnswers: `${BASE_URL}add-question-answers`

}