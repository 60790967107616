import React, { useState, useEffect } from 'react'
import Achievements from '../components/globals/Achievements'
import points from '../assets/images/badge-9491.png'
import coins from '../assets/images/badge-9488.png'
import badge from '../assets/images/badge-9489.png'
import badge5 from '../assets/images/badge-9490.png'
import coin from '../assets/images/coin.png'
import ai from '../assets/images/ai.png'
import coinImage from '../assets/images/coin-image.png'
import fire from '../assets/images/fire.png'
import analytics from '../assets/images/analytics.png'
import QuestionItem from '../components/globals/QuestionItem'
import profileimage from '../assets/images/profile-image.png'
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton';
import { useNavigate } from 'react-router-dom'
import CustomModal from '../components/globals/CustomModal'
import { useRecoilState } from 'recoil';
import { userAtom } from "../store/authAtom"
import { getProfileAPI } from '../services/authAPI'




function Profile() {

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useRecoilState(userAtom);

  const [profile, setProfile] = useState(null);

  useEffect(()=>{
    getProfileAPI(user.id).then((res)=>{
      console.log("res", res)
    })
    .catch((err)=>{
      console.log("err", err)
    })
  },[])



  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='achievement'>
      <div className='container pb-4'>

        <div className='row pt-4'>
          <CustomPrimaryButton
            btnTextPro="Edit Profile"
            classNamePro="btn-custom-achievement"
            customWelcomeClass="btn-achievement-custom-box"
            handleOnClick={() => {
              navigate("/admin/edit-profile")
            }}
          />
        </div>

        <div class="container text-center">
          <div class="profile pt-5 d-inline-block">
            <div class="profile-image-container d-inline-block">
              <img src={user?.profile_picture ? `${process.env.REACT_APP_BASE_URL}/${user?.profile_picture.media_path}` : profileimage} class="profile-image" alt="Profile Image" />
            </div>
            <div class="text-white">
              <h2 class="skranji-regular mt-4">{user.name}</h2>
              <p class="mt-2">{user.email}</p>
            </div>
          </div>
        </div>

        <h2 className='pt-4 text-white skranji-regular'>Statistics</h2>
        <div className='row mb-4'>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={coinImage}
              achievementPara="Coins"
              achievementTitle="5K"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={fire}
              achievementPara="50"
              achievementTitle="Day Streaks"
            />
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <h2 className='pt-4 text-white skranji-regular'>Badges</h2>
          <h2 className='pt-4 text-white skranji-regular'>View all</h2>
        </div>
        <div className='row'>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={points}
              // achievementPara="Points"
              isSpecial={false}
              achievementTitle="Bitcoin Master"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={coins}
              isSpecial={true}
              handleOnClick={handleButtonClick}
              // achievementPara="Coins"
              achievementTitle="Business Master"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={badge}
              isSpecial={true}
              // achievementPara="Badge"
              achievementTitle="Crypto Master"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={badge5}
              isSpecial={true}
              // achievementPara="Points"
              achievementTitle="A.I Master"
            />
          </div>
        </div>

        {/* <div className='row custom-space-profile'>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={badge6}
            // achievementPara="Points"
            // achievementTitle="5K"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={badge2}
            // achievementPara="Coins"
            // achievementTitle="5K"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={badge3}
            // achievementPara="Badge"
            // achievementTitle="Badge"
            />
          </div>
          <div className='col-lg-3'>
            <Achievements
              achievementImage={badge4}
            // achievementPara="Points"
            // achievementTitle="5K"
            />
          </div>
        </div> */}


        <h2 className='pt-4 text-white skranji-regular'>Progress</h2>
        <div className='row mb-4'>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 100 }} customTime="4 Hours" isClickedDisabled={true} />

          </div>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 30 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={analytics} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 75 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={ai} text="Bus  iness & money skillz" progressBar={{ now: 60 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={analytics} text="Business & money skillz" progressBar={{ now: 50 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 40 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 80 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
          <div className='col-lg-4'>
            <QuestionItem welcomeicon={analytics} text="Business & money skillz" progressBar={{ now: 100 }} customTime="4 Hours" isClickedDisabled={true} />
          </div>
        </div>

      </div>


      <CustomModal
        isSpecial={true}
        size={'lg'}
        progressBar={{ now: 50 }}
        hideModal={() => setShowModal(false)}
        showModal={showModal} title="Select Ocean" onClose={handleCloseModal}>
        <div className='ocean-modal text-center'>
          <img src={coins} className='modal-image-profile mb-4' />
          <h2 className='text-white mb-5'>Business Master</h2>
          <h2 className='text-white mt-2 mb-5'>Finish Business Master Challenge to unlock this achievement</h2>
        </div>
      </CustomModal>

    </div>
  )
}

export default Profile