import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()


export const oceanAtom = atom({
    key: 'ocean',
    default: null,
    effects_UNSTABLE: [persistAtom],
});