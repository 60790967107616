import React from 'react';
import './CustomWelcomeBox.css';
import shakehand from '../../assets/images/shake-hand.gif';

function CustomWelcomeBox() {
    return (
        <div className='d-flex justify-content-center align-items-center custom-welcome-box-class'>
            <div className='bg-primary custom-welcome-inner text-inner'>
                <h1 className='text-warning text-center'>Welcome to Shark Skillz, learn grow and have fun</h1>
            </div>
            <img src={shakehand} className='shakehand' />
        </div>
    )
}

export default CustomWelcomeBox