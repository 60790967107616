import React, { useEffect } from 'react'
import './OceanStage.css'
import { IoIosLock } from 'react-icons/io'
import { FaStar } from 'react-icons/fa'
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import StarsThree from './StarsThree';
import line from '../../assets/images/line.png';

function OceanStage({ descriptionPro, handleOnClick, indexNumber, arrayLength, item, lastCompleteIndex }) {

  const isUnlockable = lastCompleteIndex >= indexNumber - 1;
  const isCompleted = item?.is_stage_complete;
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  let indexNumberClass = indexNumber === 1 ? 1 : (indexNumber % 2 === 0 ? 2 : 3);


  const getStageClass = () => {
    return 'circle-stage-locked';
  };

  const getAdditionalClass = () => {
    if (indexNumber > lastCompleteIndex + 2) {
      return 'background-color-below';
    }
    return '';
  };

  const circleClass = `${getStageClass()} ${getAdditionalClass()}`;

  const handleMouseOver = () => setShow(true);
  const handleMouseOut = () => setShow(false);

  useEffect(() => {
    if (indexNumber === 1) {
      setShow(true);
    }
  }, [indexNumber]);

  return (
    <>
      <div className={`circle-container-main circle-container-main-${indexNumberClass}`}>
        <div className='circle-container' onClick={isUnlockable ? handleOnClick : null}>
          <div>
            {isCompleted ? (
              <StarsThree isEven={indexNumber % 2 === 0} isStarColor='yellow-color' />
            ) : (
              <StarsThree isEven={indexNumber % 2 === 0} isStarColor='white-color' />
            )}

            <span
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              ref={target}
              className={`circle ${circleClass}`}
            >
              {isCompleted || isUnlockable ? (
                <h2>{indexNumber}</h2>
              ) : (
                <IoIosLock size={50} color='white' />
              )}
            </span>
          </div>

          <Overlay placement="top" target={target.current} show={show}>
            {(props) => (
              <Tooltip
                show={show}
                id="overlay-example"
                className="description-tooltip"
                {...props}
              >
                <span className='tooltip-ocean skranji-bold'>
                  {descriptionPro}
                </span>
              </Tooltip>
            )}
          </Overlay>
        </div>
        {arrayLength !== indexNumber && (
          <img src={line} alt="line" />
        )}
      </div>
    </>
  );
}

export default OceanStage;