  import React from 'react'
  import './Achievements.css'

  function Achievements({ achievementImage, achievementTitle, achievementPara, isSpecial, handleOnClick }) {
    return (
      <div onClick={() => {
        if (handleOnClick) {
            handleOnClick();
        }
    }}  className={`achievement-box text-center ${isSpecial ? 'black-and-white' : ''}`}>
        <img src={achievementImage} className='achievement-image mb-4' />
        <h4 className='mb-0 text-center skranji-regular'>{achievementPara}</h4>
        <h4 className='skranji-regular text-center'>{achievementTitle}</h4>
      </div>
    )
  }

  export default Achievements