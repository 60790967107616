import React, { useState } from 'react'
import CustomTextField from '../components/globals/CustomTextField'
import { FaEnvelope, FaLock, FaUserAlt } from 'react-icons/fa'
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/logo.gif';
import sharkskillz from '../assets/images/sharkskillz.png';
import { Link, json, useNavigate } from 'react-router-dom';
import CustomPasswordField from '../components/globals/CustomPasswordField';
import CustomCheckBox from '../components/globals/CustomCheckBox';
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton';
import CustomSocialButton from '../components/globals/CustomSocialButton';
import google from '../assets/images/google.png'
import facebook from '../assets/images/facebook.png'
import { FaLocationDot } from 'react-icons/fa6';
import CustomSelectCountryField from '../components/globals/CustomSelectCountryField'
import { useForm, FormProvider } from 'react-hook-form';
import { registerAPI } from "../services/authAPI"
import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from "../store/authAtom"
import { setTokenLocal, setUserLocal } from '../utils/Session';
import { snakeBarState, isLoaderState } from "../store/RecoilStore"
import { apiPostWithoutAuth } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';



function RegisterForm() {

    const [user, setUser] = useRecoilState(userAtom);
    const [token, setToken] = useRecoilState(tokenAtom);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


    const [urlParam, setUrlParam] = useState(window.location.pathname.split('/').pop(),);
    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);


    const methods = useForm();

    const onSubmit = (data) => {

        setBtnLoading(true)
        setIsLoaderInfo(true)
        apiPostWithoutAuth(
            ENDPOINTS.UserRegister,
            data,
            (res) => {
                navigate("/verify");
                setUser(res.data.user_details)
                setUserLocal(res.data.user_details)
                setToken(res.data.token)
                setTokenLocal(res.data.token)
                setBtnLoading(false)
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-primary",
                    snackMsg: "register successfully",
                });
                setIsLoaderInfo(false)
            },
            (err) => {
                console.log("err", err)
                setErrMsg(err.data)
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: err.data,
                });
                setBtnLoading(false)
                setIsLoaderInfo(false)
            });
    };


    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Container fluid className='p-0'>
                    <Row className='g-0'>
                        <Col md={12} lg={7}>
                            <div className='join-shark'>
                                <div className='inner-logo d-flex align-items-center'>
                                    <img src={logo} className='register-logo' />
                                    <img src={sharkskillz} className='logo-pic' />
                                </div>
                                <div className='inner-text'>
                                    <h2 className='text-white font-55'>
                                        Join SharkSkillz and unlock<br />
                                        your potential.
                                    </h2>
                                    <p className='text-white font-22 skranji-regular mt-4'>
                                        It is a long established fact that a reader will be distracted by the readable
                                        content of a page when looking at its layout. The point of using Lorem Ipsum is
                                        that it has a more-or-less normal distribution of letters, as opposed to using
                                        'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={5} className='register d-flex'>
                            <div className='d-block mx-auto text-center align-self-center register-inner'>
                                <h1 className='skranji-bold text-white'>Register</h1>
                                <h6 className='text-white mb-4'>The faster you fill up, the faster you start learning.</h6>
                                <CustomTextField
                                    icon={<FaUserAlt />}
                                    label="User name"
                                    placeholder="Chris Wayne"
                                    name="name"
                                    type="text"
                                    classNamePro="mb-2"
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                    }}
                                />


                                <CustomTextField
                                    icon={<FaEnvelope />}
                                    label="Email"
                                    placeholder="chriswayne@gmail.com"
                                    name="email"
                                    type="email"
                                    classNamePro="mb-2"
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                        pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                            message: "Please enter valid email",
                                        },
                                    }}
                                />

                                {/* <CustomTextField
                                    icon={<FaLocationDot />}
                                    label="Location"
                                    placeholder="France"
                                    name="country"
                                    type="location"
                                    classNamePro="mb-2"
                                    showGps={true}
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                    }}
                                /> */}

                                <CustomSelectCountryField
                                    label="Location"
                                    classNamePro="mb-2"
                                    name="country"
                                    validation={{
                                        required: "This field is required",
                                    }}
                                />

                                <CustomPasswordField
                                    label="Password"
                                    placeholder="************"
                                    name="password"
                                    type="password"
                                    icon={<FaLock />}
                                    classNamePro="mb-2"
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                    }}
                                />

                                <CustomPasswordField
                                    label="Confirm Password"
                                    placeholder="************"
                                    name="password_confirmation"
                                    type="password"
                                    icon={<FaLock />}
                                    classNamePro="mb-2"
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                    }}
                                />

                                <div className='d-flex gap-2 mt-2 align-items-center'>
                                    <CustomCheckBox
                                        id="registercheckbox"
                                        name="is_agreed"
                                        isRequired={true}
                                        validation={{
                                            required: "Required",
                                        }}
                                    />
                                    <p className='text-start mb-0 text-white font-16 '>By signing in to  <Link to="/sharkskillz" className='text-warning'>SharkSkillz</Link> you agree to our
                                        <Link to="/termsconditions" className='text-warning'> Terms and Conditions</Link></p>
                                </div>

                                {errMsg && <span className="error-message">{errMsg}</span>}

                                <div className='mt-2'>
                                    <CustomPrimaryButton
                                        btnTextPro="Create"
                                        btnLoading={btnLoading}
                                        handleOnClick={() => {
                                            // navigate('/welcome');
                                        }}
                                    />
                                </div>

                                <p className='mt-2 text-white or-line'>OR</p>

                                <CustomSocialButton
                                    btnTextPro="Continue with Google"
                                    classNamePro="mb-4"
                                    icon={google}
                                    handleOnClick={() => {
                                        navigate('/register-form');
                                    }}
                                />

                                <CustomSocialButton
                                    btnTextPro="Continue with Facebook"
                                    classNamePro="mb-4"
                                    icon={facebook}
                                    handleOnClick={() => {
                                        navigate('/register-form');
                                    }}
                                />

                                <p className='mt-2 text-white'>Already have an account? <Link to="/login" className='text-warning'> Login!</Link></p>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </form>
        </FormProvider>
    )
}

export default RegisterForm