import React from 'react';
import './CustomStageBox.css';
import logo from '../../assets/images/logo.gif';

function CustomStageBox() {
    return (
        <div>
            <div className='w-100 text-center'>
            <img src={logo} className='coin-stage' />
            </div>
            <div className='bg-primary custom-stage-inner text-stage-inner'>
                <h4 className='text-warning text-center skranji-bold'>It cost 10 coins to unlock the stage. Let’s learn some shark skills.</h4>
            </div>
        </div>
    )
}

export default CustomStageBox