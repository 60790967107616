import React from 'react'
import './CoinWalletCard.css'

function CoinWalletCard({ walletimage, wallettext, walletamount }) {
    return (
            <div className='custom-wallet'>
                <img src={walletimage} className='walletimage' />
                <div>
                    <h5 className='skranji-regular'>{wallettext}</h5>
                    <p className='skranji-bold'>{walletamount}</p>
                </div>
            </div>
    )
}

export default CoinWalletCard