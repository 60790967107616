import React, { useState } from 'react'
import CustomQuestBox from '../components/globals/CustomQuestBox'
import CustomBusinessMoneyButton from '../components/globals/CustomBusinessMoneyButton'
import BuyCoinComponent from '../components/globals/BuyCoinComponent'
import QuestionItem from '../components/globals/QuestionItem';
import points from '../assets/images/points.png'
import coin from '../assets/images/coin.png'
import ai from '../assets/images/ai.png'
import analytics from '../assets/images/analytics.png'
import CustomModal from '../components/globals/CustomModal';
import { useNavigate } from 'react-router-dom';

function Quest() {

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();


  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='quest'>
      <div className='container-fluid p-4'>
        <div className='d-flex justify-content-between align-items-center pb-5 custom-quest-mb-class'>
          <CustomBusinessMoneyButton
            btnTextPro="Business and Money Skillz"
            classNamePro="btn-custom-leaderboard"
            customWelcomeClass="custom-leaderboard-class"
            handleOnClick={handleButtonClick}
          />
          <BuyCoinComponent handleOnClick={() => navigate('/admin/coin-purchase')} />
        </div>
        <h1 className='text-white'>Quests :</h1>
        <div className='row pb-3'>
          <div className='col-lg-4'>
            <CustomQuestBox isLocked={false} />
          </div>
          <div className='col-lg-4'>
            <CustomQuestBox isLocked={true} />
          </div>
          <div className='col-lg-4'>
            <CustomQuestBox isLocked={true} />
          </div>
          <div className='col-lg-4 mt-4'>
            <CustomQuestBox isLocked={true} />
          </div>
        </div>
      </div>

      <CustomModal
        size={'md'}
        hideModal={() => setShowModal(false)}
        showModal={showModal}
        title="Select Ocean" onClose={handleCloseModal}>
        <div className='ocean-modal text-center'>
          <h2 className='text-white'>Select Ocean</h2>
          <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 100 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 30 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={analytics} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
        </div>
      </CustomModal>
    </div>


  )
}

export default Quest